.avatar {
    display: flex;
    position: relative;
    flex-direction: column;
    width: clamp(4rem, 8rem, 6.666vw);
    height: clamp(4rem, 8rem, 6.666vw);
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.4s ease-out;

    &:hover {

        label {
            opacity: 1;
        }
    }

    img {
        // width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.avatar__submit {
    display: flex;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: #d4e1f0;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    user-select: none;
    outline: none;
    text-shadow: none;
    appearance: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
    opacity: 0;
    cursor: pointer;
    transition: all 0.4s ease-out;

    svg {
        font-size: clamp(1em, 1.25em, 1.1vw);
        color: #374758;
        background: none;
    }
}

.upload__input {
    display: none;
}