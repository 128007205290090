.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: none;
    user-select: none;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: none;
  }
  
  .leftControl,
  .rightControl {
    margin: 1rem;
    cursor: pointer;
    display: flex;
    padding: 1rem;
    align-items: center;
    background: none;
    opacity: 0.8;

    svg {
        font-size: 2em;
        color: var(--neutral-light);
        background: none;
    }
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }