.chat {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 1;
    background: none;
    padding: 0;

    @media screen and(max-width: 1440px){
        flex-direction: column;
        height: fit-content;
    }
}

.chatbox__container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 75%;
    height: 100%;
    align-items: flex-start;
    border-radius: 32px;
    background-color: #B7CEE9;
    // padding: 32px;
    z-index: 1;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and(max-width: 1440px){
        height: 90vh;
        width: 100%;
    }
}

.chatbox__messages-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    background: none;
    padding: 6rem 2rem 5rem 2rem;
    overflow-y: scroll;
    gap: 1rem;

    @media screen and(max-width: 720px){
        padding: 6rem 1.5rem 5.5rem 1.5rem;
    }
}

.messages__row-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: center;
    padding: 1rem 0;
    background: none;
    gap: 6px;
    overflow: unset;
}

.messages__box {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: 640px;
    height: fit-content;
    padding: 12px;
    align-items: flex-start;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 3vw);
        font-weight: 400;
        width: 100%;
        height: auto;
        background: none;
        overflow-wrap: break-word;
    }

    @media screen and(max-width: 1440px){
        max-width: 480px
    }
    @media screen and(max-width: 1024px){
        max-width: 240px
    }
}

.messages__timestamp {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    p {
        font-style: normal;
        font-size: 0.75em;
        font-weight: 400;
        background: none;
    }
}

.chatbox__headecontainer {
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 6rem;
    top: 0;
    left: 0;
    background-color: var(--neutral-dark);
    align-items: center;
    justify-content: center;
}

.chaboxheadcont__wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background: none;
    gap: 16px;

    div:nth-child(1) {
        display: flex;
        position: relative;
        width: clamp(2.25rem, 3rem, 2.5vw);
        height: clamp(2.25rem, 3rem, 2.5vw);
        background: none;
        border-radius: 50%;
        align-items: center;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    div:nth-child(2) {
        display: flex;
        position: relative;
        flex-direction: column;
        flex-wrap: nowrap;
        width: fit-content;
        height: fit-content;
        gap: 2px;
        background: none;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: clamp(1em, 1.5em, 1.25vw);
            text-align: left;
            text-transform: uppercase;
            background: none;
            color: var(--neutral-light);
        }

        div {
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            height: fit-content;
            background: none;
            gap: 4px;
            align-items: center;

            div {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #36CD69;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.75em;
                text-align: left;
                text-transform: capitalize;
                background: none;
                color: #36CD69;
            }
        }
    }
}

.chatbox__messagebar {
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 75%;
    height: 64px;
    align-self: center;
    bottom: 32px;
    background-color: #D4E1F0;
    box-shadow: 0px 115px 46px rgba(23, 35, 49, 0.01), 0px 65px 39px rgba(23, 35, 49, 0.04), 0px 29px 29px rgba(23, 35, 49, 0.07), 0px 7px 16px rgba(23, 35, 49, 0.08), 0px 0px 0px rgba(23, 35, 49, 0.08);
    border-radius: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 32px;

    @media screen and(max-width: 1440px){
        width: 90vw;
    }
}

.chabomessagebar__fileattach {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: center;
    gap: 12px;

    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }

    div {
        height: 32px;
        width: 0px;
        border: 1px solid #8AA6C5;
        background: none;
    }
}

.chabomessagebar__messageinput {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    justify-content: left;
    background: none;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    user-select: none;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(0.75em, 1em, 1.6vw);
    color: #374758;
    text-align: center;
    background: none;
    padding: 0 2rem;
    transition: all 0.3s ease-out;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: #6c89aa;
    }
}

.chabomessagebar__sendbutton {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 2rem;
    height: 2rem;
    background-color: #374758;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    user-select: none;
    outline: none;
    text-shadow: none;
    appearance: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
    cursor: pointer;

    svg {
        font-size: 1.5em;
        color: #D4E1F0;
        background: none;
    }
}

.chatdetails__container {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    right: 0;
    width: 28%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 0;
    background-color: #D4E1F0;
    border-radius: 32px;
    padding: clamp(1.5rem,2rem,1.666vw) clamp(1.5rem,2rem,1.666vw) clamp(1.5rem,2rem,1.666vw) clamp(3rem,5.5rem,5vw);
    justify-content: space-between;

    @media screen and(max-width: 1440px){
        position: relative;
        width: 100%;
        margin-top: -4rem;
        padding: clamp(1.5rem,2rem,1.666vw);
        padding-top: 6rem;
        height: fit-content;
        gap: 2rem;
    }
}

.chatdetails__header {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    background: none;

    h4 {
        font-style: normal;
        font-size: clamp(1em, 1.5em, 1.25vw);
        font-weight: 700;
        color: #374758;
        background: none;
        text-transform: uppercase;
        letter-spacing: 0.04em;
    }
}

.chatdetails__datawrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 12px;
    background: none;
    align-items: flex-start;
}

.chadawra__rowwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background-color: #B7CEE9;
    padding: 6px;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
}

.chadawrarowwra__fieldtitle {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    align-items: flex-start;

    h5 {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 600;
        color: #374758;
        background: none;
        text-transform: uppercase;
        text-align: left;
    }
}

.chadawrarowwra__datafieldwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    gap: 8px;
    background: none;
}

.chadawrarowwra__datafield {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #D4E1F0;
    gap: 8px;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 400;
        color: #374758;
        background: none;
    }

    svg {
        font-size: clamp(0.75em, 1em, 1vw);
        color: #374758;
        background: none;
    }
}

.detailslocation__faqs {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 20px;
    background-color: #DBAB03;
    border-radius: 6px;
    overflow: hidden;
    padding: 12px;
}

.dlfaqs__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    h3 {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.25vw);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: #15212D;
        background: none;
        text-align: left;
    }

    svg {
        font-size: clamp(1.25em, 1.5em, 1.5vw);
        color: #15212D;
        background: none;
    }
}

.dlfaqs__qacontainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background: none;
    gap: 12px;
    align-items: flex-start;

    h4 {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.1vw);
        font-weight: 600;
        color: #15212D;
        text-align: left;
        background: none;
    }

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 400;
        color: #15212D;
        margin-left: 12px;
        max-height: 60px;
        background: none;
    }
}

.chatdetails__importantbox {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    padding: 12px;
    gap: 20px;
    background-color: #DBAB03;
    border-radius: 6px;
}

.chatdetails__importantbox-header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: clamp(0.875em, 1.25em, 1.25vw);
        letter-spacing: 0.04em;
        color: #15212D;
        text-transform: uppercase;
        background: none;
    }

    svg {
        font-size: clamp(1em, 1.5em, 1.5vw);
        color: #15212D;
        background: none;
    }
}

.chatdetails__importantbox-content {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 12px;
    background: none;
}

.importantbox__content-row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1.25em, 1vw);
        font-weight: 400;
        color: #15212D;
        background: none;
    }
}


