.inicio {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0px;
  z-index: 1;
  user-select: none;
}

.inicio__herosection {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  margin-top: -100px;
  border-radius: 0 0 24vw 24vw;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  overflow: hidden;
  
  img {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  div:nth-child(2) {
    display: flex;
    position: absolute;
    right: 32px;
    bottom: clamp(48vh, 62vh, 48vh);
    background: none;
    width: 639px;

    h1 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: clamp(2.5em,4em,6vw);
      letter-spacing: 0.06em;
      text-align: right;
      color: var(--neutral-light);
      background: none;
      text-transform: uppercase;

      @media screen and(max-width: 1024px){
        text-align: center;
      }
    }

    @media screen and(max-width: 1024px){
      width: 80%;
      padding: 0 24px;
      right: unset;
      justify-content: center;
      align-items: center;
    }
    @media screen and(max-width: 640px){
      width: 100%;
    }
  }
}

.downarrow {
  display: flex;
  position: absolute;
  width: fit-content;
  height: fit-content;
  background: none;
  bottom: 2rem;
  right: 50%;

  svg {
    background: none;
    color: var(--neutral-light);
    display: flex;
    font-size: 3em;
    position: relative;
  }

  @media screen and(max-width: 1024px){
    right: unset;
    justify-self: center;
    align-self: center;
  }
}

.inicio__introsection {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: center;
  gap: clamp(2rem,12rem,10vw);
  margin-top: clamp(4rem, 16rem, 13vw);
  background: none;
}

.introsection__featured-right {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  padding-left: clamp(1.5rem, 2rem, 1.66vw);
  align-items: center;
  justify-content: space-between;
  background: none;

  div:nth-child(1) {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: clamp(1.5rem, 3rem, 4.9vh);
    background: none;

    h2 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: clamp(1.5em, 4em, 4.5vw);
      letter-spacing: 00.06em;
      color: var(--neutral-dark);
      text-align: left;
      text-transform: uppercase;
      width: 37.5vw;
      background: none;

      @media screen and(max-width: 1024px) {
        width: 100%;
        text-align: center;
      }
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: clamp(1em, 2em, 2.5vw);
      color: var(--neutral-dark);
      background: none;
      width: 37.5vw;
      text-align: left;

      @media screen and(max-width: 1024px){
        width: 100%;
        text-align: center;
      }
    }

    @media screen and(max-width: 1024px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: clamp(1.5rem,3rem,4.9vh);
      flex-direction: column;
      padding: 0 24px;
    }
  }

  div:nth-child(2) {
    display: flex;
    position: relative;
    right: 0;
    width: 56vw;
    height: clamp(580px, 80vh, 60vw);
    border-radius: 32px 0px 0px 32px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media screen and(max-width: 1024px){
      width: 100%;
      border-radius: 24px;
      height: clamp(360px,80vh,80vw);
    }
  }

  @media screen and(max-width: 1024px) {
    gap: clamp(1.5rem, 2rem, 4.5vw);
    flex-direction: column;
    padding-left: unset;
  }
}

.video__container {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.introsection__featured-left {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  padding-right: clamp(1.5rem, 2rem, 1.66vw);
  align-items: center;
  justify-content: space-between;
  background: none;

  div:nth-child(1) {
    display: flex;
    position: relative;
    left: 0;
    width: 56vw;
    height: clamp(580px, 80vh, 60vw);
    border-radius: 0px 32px 32px 0px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    @media screen and(max-width: 1024px){
      order: 1;
      width: 100%;
      border-radius: 24px;
      height: clamp(360px,80vh,80vw);
    }
  }

  div:nth-child(2) {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    gap: clamp(1.5rem, 3rem, 4.9vh);
    background: none;

    h2 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: clamp(1.5em, 4em, 4.5vw);
      letter-spacing: 00.06em;
      color: var(--neutral-dark);
      text-align: right;
      text-transform: uppercase;
      width: 37.5vw;
      background: none;

      @media screen and(max-width: 1024px){
        width: 100%;
        text-align: center;
      }
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: clamp(1em, 2em, 2.5vw);
      color: var(--neutral-dark);
      background: none;
      width: 37.5vw;
      text-align: right;

      @media screen and(max-width: 1024px){
        width: 100%;
        text-align: center;
      }
    }

    @media screen and(max-width: 1024px){
      width: 100%;
      padding: 0 24px;
      align-items: center;
      justify-content: center;
      gap: clamp(1.5rem,3rem,4.9vh);
      flex-direction: column;
    }
  }

  @media screen and(max-width: 1024px){
    gap: clamp(1.5rem, 2rem, 4.5vw);
    flex-direction: column;
    padding-right: unset;
  }
}

.inicio__instalaciones {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 2rem;
  height: fit-content;
  background: none;
  align-items: center;
  margin-top: clamp(4rem, 16rem, 13vw);
  gap: clamp(2rem, 5rem, 4vw);

  @media screen and(max-width: 1024px) {
    padding: 0;
  }
}

.instalaciones__heading {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-between;


  h2 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2em, 4em, 4vw);
    letter-spacing: 00.06em;
    color: var(--neutral-dark);
    text-align: right;
    text-transform: uppercase;
    background: none;
  }

  @media screen and(max-width: 1024px){
    flex-direction: column;
    gap: clamp(1.5rem, 3rem, 3.5vw);
  }
}

.heading__picker-container {
  display: flex;
  position: relative;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  transition: all 0.4s ease-out;
}

.picker__selected {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--neutral-dark);
  // transition: all 0.4s ease-out;
  
  svg {
    font-size: clamp(1em, 2em, 1.5vw);
    color: var(--neutral-light);
    background: none;
  }
}

.picker__selected-content {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  width: fit-content;
  height: fit-content;
  background: none;
  user-select: none;
  transition: all 0.4s ease-out;

  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(1em, 2em, 1.5vw);
    color: var(--neutral-light);
    background: none;
    text-transform: uppercase;
  }

  svg {
    font-size: clamp(1em, 2em, 1.5vw);
    color: var(--neutral-light);
    background: none;
  }
}

.picker__dropdown {
  display: none;
  position: absolute;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;
  // padding: 1rem;
  align-items: center;
  margin-top: clamp(3rem, 4rem, 4vw);
  border-radius: 12px;
  background-color: var(--neutral-dark);
  z-index: 2;
  overflow: hidden;

  div {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    padding: 12px 16px;
    background: none;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    user-select: none;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: #867366;
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: clamp(1em, 2em, 1.5vw);
      color: var(--neutral-light);
      background: none;
      text-transform: uppercase;
    }

    svg {
      font-size: clamp(1em, 2em, 1.5vw);
      color: var(--neutral-light);
      background: none;
    }
  }
}

.instalaciones__tarjeta {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 82vh;
  padding: 2rem;
  background: white;
  box-shadow: -489px 0px 195px rgba(15, 37, 62, 0.01), -275px 0px 165px rgba(15, 37, 62, 0.05), -122px 0px 122px rgba(15, 37, 62, 0.09), -31px 0px 67px rgba(15, 37, 62, 0.1), 0px 0px 0px rgba(15, 37, 62, 0.1);
  border-radius: 2rem;
  align-items: flex-start;
  min-height: 460px;

  @media screen and(max-width: 1024px){
    flex-direction: column;
    height: fit-content;
    align-items: center;
    padding: 0;
    gap: 24px;
  }
}

.tarjeta__slider {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  height: 100%;
  border-radius: 2rem;
  overflow: hidden;

  @media screen and(max-width: 1440px){
    width: 58%
  }

  // img {
  //   width: 100%;
  //   height: auto;
  //   object-fit: cover;
  // }

  @media screen and(max-width: 1024px) {
    width: 100%;
    height: clamp(256px, 720px, 70vw);
    position: relative;
  }
}

.tarjeta__content {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 2rem;
  top: 0;
  margin-left: 2rem;
  width: 36%;
  background: none;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;

  @media screen and(max-width:1024px){
    position: relative;
    width: 100%;
    padding: 0 24px;
    right: unset;
    margin: unset;
    margin-bottom: 24px;
    align-items: center;
  }
}

.tarjeta__content::-webkit-scrollbar {
  display: none;
}

.content__info {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  background: none;
  justify-content: space-between;
  min-height: 640px;

  @media screen and(max-height: 860px){
    cursor: grab;
  }

  @media screen and(max-width: 1024px) {
    gap: 24px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: unset;
    min-height: unset;
  }
}

.info__heading {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  flex-wrap: nowrap;
  align-items: flex-start;
  background: none;
  gap: 18px;
  
  div:nth-child(1) {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: clamp(8px, 12px, 0.625vw);
    background: none;

    h3 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: clamp(0.75em, 1.5em, 1.25vw);
      text-align: center;
      color: var(--neutral-dark);
      text-transform: uppercase;
      background: none;
    }

    div {
      width: 6px;
      height: 6px;
      background-color: var(--neutral-dark);
      border-radius: 50%;
    }
  }

  div:nth-child(2) {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    background: none;
    align-items: flex-start;

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      text-align: left;
      font-size: clamp(1em, 1.5em, 1.25vw);
      color: var(--neutral-dark);
      background: none;

      @media screen and(max-width: 1024px){
        text-align: center;
      }
    }
  }

  @media screen and(max-width: 1024px){
    align-items: center;
  }
}

.info__minicards {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  gap: 16px;
  background: none;
  overflow-x: scroll;
  user-select: none;

  @media screen and(max-width: 1760px){
    cursor: grab;
  }
}

.info__minicards::-webkit-scrollbar {
  display: none;
}

.minicards {
  box-sizing: content-box;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: clamp(12px, 16px, 0.8333vw);
  gap: clamp(8px, 16px, 0.833vw);
  width: clamp(160px, 196px, 10.5vw);
  min-width: 160px;
  max-height: 196px;
  height: clamp(180px, 196px, 10.5vw);
  min-height: 160px;
  max-height: 196px;
  border: 1px solid var(--neutral-dark);
  border-radius: 1rem;
  background: none;
  

  div:nth-child(1) {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: flex-start;
    background: none;
    h4 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: clamp(1em, 1.03em, 1vw);
      text-align: right;
      color: var(--neutral-dark);
      text-transform: uppercase;
      background: none;
    }
  }

  div:nth-child(2) {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background: none;

    svg {
      font-size: 1.5em;
      color: var(--neutral-dark);
      background: none;
    }
  }

  div:nth-child(3) {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 12px;
    background: none;

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      color: var(--neutral-dark);
      background: none;
    }
  }
}

.info__services {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  justify-content: space-between;
  background: none;
  overflow-x: scroll;
  gap: 6rem;

  div {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    gap: 2.5rem;
    background: none;
    align-items: flex-start;
    justify-content: left;

    div {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;
      background: none;

      p {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        text-transform: uppercase;
        color: var(--neutral-dark);
        background: none;
        white-space: nowrap;
      }

      svg {
        font-size: 1em;
        color: var(--neutral-dark);
        background: none;
      }

      @media screen and(max-width: 1024px){
        width: 100%;
        justify-content: space-between;
      }
    }

    @media screen and(max-width: 1024px){
      width: 100%;
      gap: clamp(1.5rem, 3rem, 4vw);
    }
  }

  @media screen and(max-width: 1880px){
    cursor: grab;
  }

  @media screen and(max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: clamp(1.5rem, 3rem, 4vw);
  }
}

.info__services::-webkit-scrollbar {
  display: none;
}

.content__ctas {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: fit-content;
  gap: 2rem;
  background: none;

  @media screen and(max-width: 1024px){
    gap: clamp(1rem, 2rem, 2vw);
  }
}

.inicio__actividades {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 2rem;
  height: fit-content;
  background: none;
  align-items: center;
  margin-top: clamp(4rem, 16rem, 13vw);
  gap: clamp(2rem, 5rem, 4vw);

  @media screen and(max-width: 1024px){
    padding: 0;
  }
}

.actividades__heading {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  padding: 0;
  background: none;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2em,4em,4vw);
    letter-spacing: 00.06em;
    color: var(--neutral-dark);
    text-align: center;
    text-transform: uppercase;
    background: none;
  }
}

.actividades__grid-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  background: none;
  gap: clamp(1rem, 3rem, 2.5vw);
  align-items: center;
  justify-content: center;
}

.grid__container-row1 {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 30vw;
  background: none;
  gap: clamp(1rem,2rem,2vw);
  align-items: center;
  justify-content: center;

  div:nth-child(1) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 1;
    background-color: var(--neutral-dark);
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  div:nth-child(2) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 1;
    background-color: var(--neutral-dark);
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  div:nth-child(3) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 2;
    background-color: var(--neutral-dark);
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
.grid__container-row2 {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 30vw;
  background: none;
  gap: clamp(1rem,2rem,2vw);
  align-items: center;
  justify-content: center;

  div:nth-child(1) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 1;
    background-color: var(--neutral-dark);
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  div:nth-child(2) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 1;
    background-color: var(--neutral-dark);
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  div:nth-child(3) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 1;
    background-color: var(--neutral-dark);
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
.grid__container-row3 {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 60vw;
  background: none;
  gap: clamp(1rem,2rem,2vw);
  align-items: center;
  justify-content: center;

  div:nth-child(1) {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 1;
    border-radius: 24px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  div:nth-child(2) {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    gap: clamp(1rem,2rem,2vw);

    div {
      display: flex;
      flex-direction: row;
      position: relative;
      flex-grow: 1;
      background-color: var(--neutral-dark);
      border-radius: 24px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}

.inicio__ubicacion {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 2rem;
  height: fit-content;
  background: none;
  align-items: center;
  margin-top: clamp(4rem, 16rem, 13vw);
  gap: clamp(2rem, 5rem, 4vw);

  @media screen and(max-width:1024px) {
    padding: 0;
  }
}

.ubicacion__heading {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  padding: 0;
  background: none;
  align-items: center;
  justify-content: flex-end;


  h2 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2em,4em,4vw);
    letter-spacing: 00.06em;
    color: var(--neutral-dark);
    text-align: right;
    text-transform: uppercase;
    background: none;
  }
}

.ubicacion__map {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: clamp(360px, 50vw, 50vh);
  min-height: 760px;
  background: none;
  border-radius: 24px;
  overflow: hidden;

  @media screen and(max-width: 1024px){
    min-height: unset;
  }
}

.inicio__lastctas {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  background: none;
  gap: clamp(1.5rem, 3rem, 2vw);
  margin-top: clamp(2rem,4rem,4vw);
  align-items: center;
  justify-content: center;
}

.inicio__faqs {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  background: none;
  align-items: center;
  justify-content: center;
  margin-top: clamp(2rem, 8rem, 7.5vw);
}

.faqs__box {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 16px;
  width: clamp(320px, 720px, 72vw);
  height: fit-content;
  background-color: var(--semantic-warning);
  align-items: flex-start;
  justify-content: left;
  gap: 16px;
  border-radius: 24px;
}

.faqs__box-question {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  background: none;
  padding: 0;
  align-items: flex-start;
  justify-content: left;
  gap: 12px;

  h3 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1em, 1.5em, 1.25vw);
    background: none;
    color: #15212D;
    text-align: left;
  }

  p {
    padding-left: 16px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(0.75em, 1em, 1vw);
    background: none;
    color: #15212D;
    text-align: left;
  }
}