.calendarform-loader {
    --uib-size: 80px;
    --uib-speed: 1.55s;
    --uib-color: var(--neutral-dark);
    --uib-line-weight: 5px;
    position: relative;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: var(--uib-line-weight);
    width: var(--uib-size);
    border-radius: calc(var(--uib-line-weight) / 2);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .calendarform-loader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--uib-color);
    opacity: 0.1; 
  }
  
  .calendarform-loader::after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: calc(var(--uib-line-weight) / 2);
    animation: loader var(--uib-speed) ease-in-out infinite;
    transform: translateX(-90%);
    background-color: var(--uib-color); 
  }
  
  .calendar-loader {
    --uib-size: 80px;
    --uib-speed: 1.55s;
    --uib-color: var(--neutral-dark);
    --uib-line-weight: 5px;
    position: relative;
    display: flex;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    height: var(--uib-line-weight);
    width: var(--uib-size);
    border-radius: calc(var(--uib-line-weight) / 2);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .calendar-loader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--uib-color);
    opacity: 0.1; 
  }
  
  .calendar-loader::after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: calc(var(--uib-line-weight) / 2);
    animation: loader var(--uib-speed) ease-in-out infinite;
    transform: translateX(-90%);
    background-color: var(--uib-color); 
  }
  
  @keyframes loader {
    0%,
    100% {
    transform: translateX(-90%);
    }
  
    50% {
    transform: translateX(90%);
    }
  }