.account {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 1;
    background: none;
    padding: 0;

    @media screen and(max-width: 1440px){
        flex-direction: column;
        height: fit-content;
    }
}

.account__detailscontainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 75%;
    height: 100%;
    align-items: flex-start;
    border-radius: 32px;
    background-color: #D4E1F0;
    padding: clamp(1.5rem, 2rem, 1.666vw);
    z-index: 1;
    overflow: hidden;

    @media screen and(max-width: 1440px){
        width: 100%;
    }
}

.accdetailscontainer__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    background: none;
    width: fit-content;
    height: auto;
    align-items: center;
    margin-bottom: 64px;
}

.accdetheader__avatar {
    display: flex;
    position: relative;
    flex-direction: column;
    width: clamp(4rem, 8rem, 6.666vw);
    height: clamp(4rem, 8rem, 6.666vw);
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.accdetheader__userinfowrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: auto;
    align-items: flex-start;
    gap: 8px;
    background: none;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1.5em, 2em, 1.666vw);
        text-align: left;
        color: #374758;
        text-transform: capitalize;
        background: none;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75em, 1em, 1.25vw);
        text-align: left;
        color: #6F8093;
        background: none;
    }

    p::first-letter {
        text-transform: capitalize;
    }
}

.accdetfields__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    align-items: flex-start;
    gap: 2rem;
    background: none;
}

.accdetfiewrap__row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background-color: #B7CEE9;
    border-radius: 6px;
    padding: 6px 16px;

    @media screen and(max-width: 480px){
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}

.accdetfiewraprow__title {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    background: none;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: clamp(1em, 1.5em, 1.25vw);
        color: #374758;
        background: none;
        text-transform: uppercase;
    }
}

.accdetfiewraprow__inputwrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    background: none;
    gap: 8px;
    align-items: center;

    @media screen and(max-width: 480px){
        width: 100%;
        justify-content: space-between;
    }
}

.accdetfiewraprow__inputedit {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: none;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background-color: #D4E1F0;
    border-radius: 6px;
    padding: 6px;
    cursor: pointer;

    svg {
        font-size: clamp(1em, 1.5em, 1.25vw);
        color: #374758;
        background: none;
    }
}

.accdetfiewraprow__input {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: clamp(87px, 306px, 50vw);
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    background-color: #D4E1F0;
    border: none;
    font-style: normal;
    font-size: clamp(1em, 1.5em, 1.25vw);
    font-weight: 400;
    color: #374758;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.3s ease-out;
    user-select: none;

    &:focus {
        border: none;
        appearance: none;
        text-shadow: none;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: #6F8093;
    }
}

.accdetform__submit {
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: auto;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background-color: #ebf2fa;
    border-radius: 8px;
    align-self: flex-end;
    bottom: 32px;
    user-select: none;
    outline: none;
    text-shadow: none;
    appearance: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.4s ease-out;

    &:hover {
        background-color: #374758;

        p {
            color: #ebf2fa
        }

        svg {
            color: #ebf2fa
        }
    }

    p {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1em, 1.25em, 1vw);
        text-align: right;
        text-transform: uppercase;
        background: none;
        color: #374758;
        transition: color 0.4s ease-out;
    }

    svg {
        font-size: clamp(1em, 1.25em, 1vw);
        color: #374758;
        background: none;
        transition: color 0.4s ease-out;
    }

    @media screen and(max-width: 1440px){
        position: relative;
        margin-top: 2rem;
        bottom: unset;
    }
}

.account__mcontrolcontainer {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    right: 0;
    width: 28%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 0;
    background-color: #B7CEE9;
    border-radius: 32px;
    padding: clamp(1.5rem,2rem,1.666vw) clamp(1.5rem,2rem,1.666vw) clamp(1.5rem,2rem,1.666vw) clamp(3rem,5.5rem,5vw);
    justify-content: space-between;

    @media screen and(max-width: 1440px){
        position: relative;
        width: 100%;
        padding: clamp(1.5rem, 2rem, 1.666vw);
        top: -4rem;
        padding-top: 6rem;
        height: fit-content;
        right: unset;
    }
}

.accmcontrol__reshistorywrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: center;
    background: none;
    gap: 2rem;

    @media screen and(max-width: 1440px){
        height: fit-content;
    }
}

.accmconreshi__header {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    background: none;
    align-items: flex-start;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1em, 1.5em, 1.25vw);
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: #374758;
        background: none;
    }
}

.accmconreshi__dateswrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 1rem;
    background: none;
}

.accmconreshidawra__row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    padding: 6px;
    border-radius: 6px;
    background-color: #D4E1F0;
    align-items: center;
    justify-content: space-between;

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 600;
        color: #374758;
        background: none;
    }
}

.accmconreshidawraro__date {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    background-color: #B7CEE9;
    border-radius: 6px;
    padding: 6px;
    gap: 4px;


    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 600;
        color: #374758;
        background: none;
        text-transform: capitalize;
    }

    svg {
        font-size: clamp(0.75em, 1em, 1vw);
        color: #DBAB03;
        background: none;
    }
}

.accmconcontent__ctas {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
    gap: clamp(1rem, 2rem, 1.666vw);
    bottom: -42px;
    background: none;

    @media screen and(max-width: 1440px){
        bottom: unset;
    }
  }


.accpan__logout {
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: auto;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background-color: #D4E1F0;
    border-radius: 8px;
    align-self: flex-end;
    bottom: 32px;
    user-select: none;
    outline: none;
    text-shadow: none;
    appearance: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
    cursor: pointer;

    transition: background-color 0.4s ease-out;

    &:hover {
        background-color: #374758;

        p {
            color: #D4E1F0;
            transition: color 0.4s ease-out;
        }

        svg {
            color: #D4E1F0;
            transition: color 0.4s ease-out;
        }
    }


    p {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1em, 1.25em, 1vw);
        text-align: right;
        text-transform: uppercase;
        background: none;
        color: #374758;
    }

    svg {
        font-size: clamp(1em, 1.25em, 1vw);
        color: #374758;
        background: none;
    }

    @media screen and(max-width: 1440px){
        position: relative;
        margin-top: 4rem;
        bottom: unset;
    }
}