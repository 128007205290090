.blog {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    width: 100%;
    height: fit-content;
    grid-gap: 8rem;
    justify-items: start;
    padding: clamp(1.5rem, 2rem, 1.66vw);
    border-radius: 32px;
    background-color: var(--neutral-light_2);

    a:nth-child(even) {
        justify-items: end;

        header {
            justify-items: end;
            div.pcheader_time {
                place-self: flex-end;
                justify-items: end;
            }
        }

        div.pc_content {
            grid-template-columns: 1fr 0.4fr;
            @media screen and(max-width: 1024px) {
                grid-template-columns: 1fr;
            }

            div.pc_content-heroimg {
                order: 1;
                @media screen and(max-width: 1024px) {
                    order: 0;
                }
            }
            div.pc_content-excerpt{
                div.pccontent_excerpt-cta {
                    place-self: start;
                }
            }
        }
    }
}
.post_card {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 1.5rem;
    width: 100%;
    height: fit-content;
    text-decoration: none;
    background: none;
}
.post_card-header {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    width: fit-content;
    height: fit-content;

    cursor: pointer;
}
.pcheader_title {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1.5em, 3em, 2.5vw);
    color: var(--neutral-dark_2);
}
.pcheader_time {
    display: grid;
    position: relative;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    justify-content: start;
    align-items: center;
}
.pcheader_time-label {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(0.75em, 1.5em, 1.25vw);
    white-space: nowrap;
    color: var(--neutral-dark_2);
}
.pcheader_time-value {
    font-style: normal;
    font-weight: 300;
    font-size: clamp(0.75em, 1.5em, 1.25vw);
    text-transform: capitalize;
    color: var(--neutral-dark_2);
    white-space: nowrap;
}
.pc_content {
    display: grid;
    position: relative;
    grid-template-columns: 0.4fr 1fr;
    order: 1;
    grid-template-rows: 1fr;
    grid-gap: 3rem;
    width: 100%;
    height: fit-content;

    @media screen and(max-width: 1024px) {
        grid-template-columns: 1fr;
    }
}
.pc_content-heroimg {
    display: flex;
    position: relative;
    flex-direction: column;
    width: clamp(24rem, 48rem, 42vw);
    height: clamp(16rem, 30rem, 25vw);
    border-radius: 16px;
    box-shadow: 0px 361px 144px rgba(15, 37, 62, 0.01), 0px 203px 122px rgba(15, 37, 62, 0.05), 0px 90px 90px rgba(15, 37, 62, 0.09), 0px 23px 50px rgba(15, 37, 62, 0.1), 0px 0px 0px rgba(15, 37, 62, 0.1);
    align-items: center;
    justify-content: center;

    overflow: hidden;
    cursor: pointer;

    @media screen and(max-width: 1024px) {
        width: 100%;
        height: auto;
    }
}
.pc_content-heroimg img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: auto;
}
.pc_content-excerpt {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: 0.9fr 0.1fr;
    grid-gap: 1rem;
}
.pccontent_excerpt-text {
    font-style: normal;
    font-weight: 400;
    font-size: clamp(1.5em, 2em, 1.875vw);
    color: var(--neutral-dark_2);
    transition: underline 0.3s ease-out;
    
    &:hover {
        text-decoration: underline;
    }

    cursor: pointer;
}
.pccontent_excerpt-cta {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: none;
    gap: 12px;

    cursor: pointer;
}
.pccontent_excerpt-cta p{
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1.25em, 1.5em, 1.25vw);
    color: var(--neutral-dark_2);
    text-transform: capitalize;
    text-decoration: none;
    transition: letter-spacing 0.4s ease-out;

    &:hover {
        letter-spacing: 0.04em;
    }
}
.pccontent_excerpt-cta svg{
    font-size: 32px;
    color: var(--neutral-dark_2);
}