.post {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: start;
    width: 100%;
    height: fit-content;
    padding: clamp(1.5rem, 2rem, 1.66vw);
    border-radius: 32px;
    background-color: var(--neutral-light_2);
}
.post__header {
    display: grid;
    position: relative;
    grid-template-columns: auto auto;
    width: 100%;
    height: fit-content;
    align-items: start;
    justify-content: space-between;
    margin-bottom: clamp(1.5rem, 2rem, 1.666vw);
}
.post__header-backbutton {
    display: grid;
    position: relative;
    grid-template-columns: min-content;
    grid-template-rows: min-content;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    
    svg {
        font-size: clamp(2em, 4.5em, 3.75vw);
        color: var(--neutral-dark_2);
    }
}
.post__header-title {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    place-self: end;
    justify-self: end;
    align-items: end;
    justify-items: end;
    grid-gap: 8px;
}
.pheader__h1 {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1.5em, 5.75em, 4vw);
    color: var(--neutral-dark_2);
    text-transform: capitalize;
    white-space: wrap;
    text-align: right;
}
.pheader__label {
    display: grid;
    position: relative;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    grid-gap: 8px;
    justify-content: end;
    align-items: center;
}
.pheader_time-label {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(0.75em, 1.5em, 1.25vw);
    color: var(--neutral-dark_2);
    white-space: nowrap;
}
.pheader_time-value {
    font-style: normal;
    font-weight: 300;
    font-size: clamp(0.75em, 1.5em, 1.25vw);
    text-transform: capitalize;
    color: var(--neutral-dark_2);
    white-space: nowrap;
}
.post__heroimage {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: clamp(30rem, 52rem, 42vw);
    box-shadow: 0px -67px 27px rgba(15, 37, 62, 0.02), 0px -38px 23px rgba(15, 37, 62, 0.08), 0px -17px 17px rgba(15, 37, 62, 0.14), 0px -4px 9px rgba(15, 37, 62, 0.16), 0px 0px 0px rgba(15, 37, 62, 0.16);
    border-radius: 16px;
    margin-bottom: 4rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: auto;
    }

    @media screen and(max-width: 1024px) {
        height: auto;
    }
}
.post__body {
    display: grid;
    position: relative;
    grid-auto-flow: row;
    grid-gap: 1.5rem;
    width: 100%;
    height: fit-content;
    align-items: start;
    justify-items: start;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: clamp(1em, 1.5em, 1.5vw);
        line-height: 140%;
        color: var(--neutral-dark_2);
    }
    section {
        display: grid;
        position: relative;
        grid-auto-flow: row;
        grid-gap: 1rem;
        width: 100%;
        height: fit-content;
        align-items: start;
        justify-items: start;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: clamp(1.25em, 1.5em, 1.5vw);
            color: var(--neutral-dark_2);
            text-align: left;
            white-space: normal;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: clamp(1em, 1.5em, 1.5vw);
            color: var(--neutral-dark_2);
            text-align: left;
        }
        img {
            max-width: 100%;
            border-radius: 16px;
            box-shadow: 0px 361px 144px rgba(15, 37, 62, 0.01), 0px 203px 122px rgba(15, 37, 62, 0.05), 0px 90px 90px rgba(15, 37, 62, 0.09), 0px 23px 50px rgba(15, 37, 62, 0.1), 0px 0px 0px rgba(15, 37, 62, 0.1);
            height: auto;
        }
    }
    section#list {
        display: grid;
        position: relative;
        grid-auto-flow: row;
        grid-gap: 1rem;
        width: 100%;
        height: fit-content;
        align-items: start;
        justify-items: start;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: clamp(1.25em, 1.5em, 1.5vw);
            color: var(--neutral-dark_2);
            text-align: left;
            white-space: nowrap;
        }
        ol {
            font-style: normal;
            font-weight: 400;
            font-size: clamp(1em, 1.5em, 1.5vw);
            color: var(--neutral-dark_2);
            text-align: left;
            list-style: inside;
            padding: 8px;
            border-radius: 12px;

            li{
                font-style: normal;
                font-weight: 400;
                font-size: clamp(1em, 1.5em, 1.5vw);
                color: var(--neutral-dark_2);
                text-align: left;
                }
        }
    }

    div#fwimage {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: clamp(30rem, 52rem, 42vw);
        box-shadow: 0px -67px 27px rgba(15, 37, 62, 0.02), 0px -38px 23px rgba(15, 37, 62, 0.08), 0px -17px 17px rgba(15, 37, 62, 0.14), 0px -4px 9px rgba(15, 37, 62, 0.16), 0px 0px 0px rgba(15, 37, 62, 0.16);
        border-radius: 16px;
        margin-bottom: 4rem;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: auto;
        }

        @media screen and(max-width: 1024px) {
            height: auto;
        }
    }
}