@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root {
  --font-main: "Lato", sans-serif;
  --primary-color: #3a9659;
  --secondary-color: #7d83ff;
  --semantic-danger: #d34423;
  --semantic-danger_soft: #e9bfb5;
  --semantic-warning: #dbab03;
  --semantic-success: #64e890;
  --semantic-safety: #91cdfa;
  --neutral-dark_2: #374758;
  --neutral-dark_3: #15212d;
  --neutral-light_2: #d4e1f0;
  --neutral-light_3: #b7cee9;
  --neutral-dark: #483b32;
  --neutral-light: #ebf2fa;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scrollbar-color: #3075c5 #b7cee9;
  scrollbar-width: thin;
  /* background: var(--neutral-light); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-family: var(--font-main);
  -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #b7cee9;
}

::-webkit-scrollbar-thumb {
  background-color: #3075c5;
  height: 64px;
}

.loader__container {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
  background: none;
}
 
.loader__stroke {
  fill: none;
  stroke: #483B32;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
  background: none;
}
 
@keyframes rotate4 {
  100% {
      transform: rotate(360deg);
  }
}
 
@keyframes dash4 {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  
  50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
  }
  
  100% {
      stroke-dashoffset: -125px;
  }
}

.Toastify__toast {
  background: none;
}
.Toastify__toast-container {
  background: none;
}
.Toastify__toast-body {
  background: none;
}
.Toastify__toast-icon {
  background: none;
}
.Toastify__toast-icon svg {
  background: none;
}
.Toastify__toast-body div {
  background: none;
}
.Toastify__close-button {
  background: none;
}
.Toastify__close-button svg {
  background: none
}
h1, h2, h3, h4, h5, p, div, svg, header, section {
  background: none;
}
html, body {
  background: var(--neutral-light);
}