.location {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 1;
    background: none;
    padding: 0;

    @media screen and(max-width: 1440px){
        flex-direction: column;
        height: fit-content;
    }
}

.location__mapcontainer {
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    border-radius: 32px;
    background: none;
    align-items: center;
    justify-content: center;
    z-index: 1;
    overflow: hidden;

    @media screen and(max-width: 1440px){
        width: 100%;
        height: 80vh;
    }
}

.location__detailscontainer {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    right: 0;
    width: 28%;
    height: 61.5rem;
    align-items: flex-start;
    z-index: 0;
    background-color: #B7CEE9;
    border-radius: 32px;
    padding: clamp(1.5rem, 2rem, 1.666vw);
    padding-left: clamp(3rem, 5.5rem, 5vw);
    justify-content: space-between;

    @media screen and(max-width: 1440px){
        position: relative;
        width: 100%;
        padding: clamp(1.5rem,2rem,1.666vw);
        padding-top: 5rem;
        height: fit-content;
        gap: 32px;
        top: -3rem;
    }
}

.details__headercontainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    gap: 24px;
    align-items: flex-start;
    background: none;
}

.details__header-cover {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 256px;
    background: none;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    overflow: hidden;
    
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    @media screen and(max-width: 1440px){
        height: clamp(260px, 512px, 40vw);
    }
}

.details__header-text {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    svg {
        font-size: 1.5em;
        color: #374758;
        background: none;
    }

    div {
        display: flex;
        position: relative;
        flex-direction: column;
        flex-wrap: nowrap;
        width: fit-content;
        align-items: flex-end;
        gap: 8px;
        background: none;

        h3 {
            font-style: normal;
            font-size: clamp(1em, 1.25em, 1.25vw);
            font-weight: 700;
            text-align: right;
            text-transform: uppercase;
            color: #374758;
            background: none;
        }

        p {
            max-width: 360px;
            font-style: normal;
            font-size: 0.75em;
            font-weight: 400;
            text-align: right;
            color: #374758;
            background: none;
        }
    }
}

.details__linkscontainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    background: none;
    gap: 16px;
}

.linkscontainer__row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    padding: 6px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #D4E1F0;
    text-decoration: none;
    border-radius: 6px;

    p {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.25vw);
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        color: #374758;
        background: none;
    }

    svg {
        font-size: clamp(1.25em, 1.5em, 1.5vw);
        color: #374758;
        background: none;
    }
}

.detailslocation__faqs {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: flex-start;
    gap: 20px;
    background-color: #DBAB03;
    border-radius: 6px;
    overflow: hidden;
    padding: 12px;
}

.dlfaqs__header {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background: none;

    h3 {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.25vw);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: #15212D;
        background: none;
        text-align: left;
    }

    svg {
        font-size: clamp(1.25em, 1.5em, 1.5vw);
        color: #15212D;
        background: none;
    }
}

.dlfaqs__qacontainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    background: none;
    gap: 12px;
    align-items: flex-start;

    h4 {
        font-style: normal;
        font-size: clamp(1em, 1.25em, 1.1vw);
        font-weight: 600;
        color: #15212D;
        text-align: left;
        background: none;
    }

    p {
        font-style: normal;
        font-size: clamp(0.75em, 1em, 1vw);
        font-weight: 400;
        color: #15212D;
        margin-left: 12px;
        max-height: 60px;
        background: none;
    }
}