.notfound {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 48px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    user-select: none;
    background-color: var(--neutral-light);
}

.notfound__bg-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: none;

    img {
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
        
        @media screen and(max-width: 1440px) {
            order: 1;
        }
    }

    @media screen and(max-width: 1440px) {
        flex-direction: column;
        align-items: center;
    }
}

.notfound__herotext {
    display: flex;
    position: absolute;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    top: 60px;
    right: 60px;
    justify-content: right;
    background: none;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(2em,4.5em,3.75vw);
        color: #A32D12;
        text-align: right;
        background: none;
    }

    h2 {
        font-style: normal;
        font-weight: 500;
        font-size: clamp(1em,2.25em,1.875vw);
        color: #A32D12;
        text-align: right;
        background: none;
    }

    @media screen and(max-width: 1440px){
        margin-top: unset;
        margin-bottom: 2rem;
        align-items: center;
        order: 0;
        position: relative;
        right: unset;
        top: unset;
    }
}