.auth {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 2rem;
    width: 100%;
    height: 100vh;
    background-color: var(--neutral-light);
    align-items: center;
    justify-content: center;
}

.auth__topbar {
    display: none;
    position: absolute;
    flex-direction: row;
    width: fit-content;
    height: 6rem;
    align-items: center;
    justify-content: center;
    background: none;
    overflow: hidden;
    top: 2rem;

    svg {
        font-size: clamp(16em, 24vw, 18vw);
        color: var(--neutral-light);
        background: none;
    }
}

.auth__form {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: clamp(320px, 30vw, 28vw);
    height: auto;
    align-items: flex-start;
    background-color: var(--neutral-light);
    border-radius: 16px;
    gap: 24px;
    padding: 2rem;
    box-shadow: 0px 165px 66px rgba(0, 0, 0, 0.02), 0px 93px 56px rgba(0, 0, 0, 0.08), 0px 41px 41px rgba(0, 0, 0, 0.13), 0px 10px 23px rgba(0, 0, 0, 0.15), 0px 0px 0px rgba(0, 0, 0, 0.15);
}

.authform__header {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    gap: 8px;
    background: none;

    h1 {
        font-style: normal;
        font-size: clamp(2em, 2vw, 2vw);
        font-weight: 700;
        color: var(--neutral-dark);
        background: none;
        text-align: left;
        text-transform: capitalize;
    }

    p {
        font-style: normal;
        font-size: clamp(0.875em, 1vw, 1vw);
        font-weight: 400;
        text-align: left;
        color: var(--neutral-dark);
        background: none;
    }
}

.authform__input {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    padding: 20px 16px;
    background-color: #fff;
    border-radius: 12px;
    align-items: flex-start;
    justify-content: left;
    border: none;
    color: var(--neutral-dark);
    font-style: normal;
    font-size: clamp(1em, 1vw, 1vw);
    font-weight: 500;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    outline: none;
    appearance: none;
    transition: all 0.2s ease-out;

    &:focus {
        box-shadow: 0px 64px 26px rgba(97, 107, 117, 0.01), 0px 36px 22px rgba(97, 107, 117, 0.05), 0px 16px 16px rgba(97, 107, 117, 0.09), 0px 4px 9px rgba(97, 107, 117, 0.1), 0px 0px 0px rgba(97, 107, 117, 0.1);
        border: none;
        appearance: none;
        text-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: #929EAC;
      }
}

.authform__submit {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 3rem;
    padding: 0.75em;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: none;
    font-style: normal;
    font-size: clamp(0.875em, 0.875vw, 0.875vw);
    font-weight: 500;
    text-align: center;
    border: 2px solid var(--neutral-dark);
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    outline: none;
    text-shadow: none;
    box-shadow: none;
    color: var(--neutral-dark);
    transition: all 0.3s ease-out;

    &:hover {
        background-color: var(--neutral-dark);
        color: var(--neutral-light);
    }
}

.authform__loading {
    font-style: normal;
    font-size: 2em;
    font-weight: 400;
    text-align: center;
    color: var(--neutral-dark);
    background: none;
}

.authform__oauthlogin {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: none;
    width: 100%;
    height: 100%;
    padding: 16px;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 12px;
    font-style: normal;
    font-size: clamp(0.875em, 0.875vw, 0.875vw);
    font-weight: 500;
    text-align: center;
    border: 2px solid var(--neutral-dark);
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    outline: none;
    text-shadow: none;
    box-shadow: none;
    color: var(--neutral-dark);
    transition: all 0.3s ease-out;
    gap: 6px;

    &:hover {
        background-color: var(--neutral-dark);
        color: var(--neutral-light);

        svg {
            color: var(--neutral-light);
        }
    }
    
    svg {
        font-size: 1.5em;
        color: var(--neutral-dark);
        background: none;
    }

    @media screen and(max-width: 1280px){
        gap: 4px;
        padding: 8px;
    }
}

.oauthoptions_wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: 3rem;
    background: none;
    gap: 1.5rem;
    align-items: flex-start;
}